import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getBookmarks, getUserCredentials } from '../reducers';
import Item from './Item';
import { TYPE_MENU } from '../utils/Constants';
import { findFirstNode } from '../utils/TreeUtil';
import '../assets/css/Content.css';
import { setBookmarks } from '../actions/bookmarks';

Content.propTypes = {
    menuId: PropTypes.string.isRequired,
};

function Content(props) {
    const { menuId } = props;
    const bookmarks = useSelector(getBookmarks);
    const dispatch = useDispatch();
    const user = useSelector(getUserCredentials);
    let menuNode = findFirstNode(bookmarks, menuId);
    if (menuNode == null) {
        [menuNode] = bookmarks.menu;
    }

    const onAddMenu = () => {
        bookmarks.sequenceId++;
        const newID = bookmarks.sequenceId.toString();
        menuNode.children.push({
            name: '', type: 'menu', parent: menuNode, id: newID, children: [], edit: true,
        });
        dispatch(setBookmarks({ ...bookmarks }));
    };
    const onAddLink = () => {
        bookmarks.sequenceId++;
        const newID = bookmarks.sequenceId.toString();
        menuNode.children.push({
            name: '', type: 'link', url: 'http://', parent: menuNode, id: newID, edit: true,
        });
        dispatch(setBookmarks({ ...bookmarks }));
    };
    // console.log(`Content selection:${menuId}`);
    const header = ((menuNode && menuNode.name) ? menuNode.name : '');

    return (
        <div className="content-padding">
            <div className="content-header">{header}</div>
            {menuNode.children && menuNode.children.length > 0 && menuNode.children
                .filter((child) => child.type === TYPE_MENU)
                .sort((n1, n2) => (n1.name === '' ? 1 : n1.name.localeCompare(n2.name)))
                .map((child) => (<Item item={child} key={child.id} />))}
            {user && (
                <button type="button" className="link-button" onClick={onAddMenu}>
                    <div className="fa fa-plus MenuItemIcon" />
                    <div className="fa fa-folder-o MenuItemIcon" />
                </button>
            )}
            <div className="content-separator" />

            {menuNode.children && menuNode.children.length > 0 && menuNode.children
                .filter((child) => child.type !== TYPE_MENU)
                .sort((n1, n2) => (n1.name === '' ? 1 : n1.name.localeCompare(n2.name)))
                .map((child) => (<Item item={child} key={child.id} />))}
            {user && (
                <button type="button" className="link-button" onClick={onAddLink}>
                    <div className="fa fa-plus MenuItemIcon" />
                    <div className="fa fa-file-o MenuItemIcon" />
                </button>
            )}
        </div>
    );
}

export default Content;
