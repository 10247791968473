import { showInfoNotification } from './notifications';

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const loginSuccess = (jwt) => ({
    type: USER_LOGIN_SUCCESS,
    payload: jwt,
});

export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const loginFailed = (userId) => ({
    type: USER_LOGIN_FAILURE,
    payload: userId,
});

export const USER_LOGOUT = 'USER_LOGOUT';
export const logoutUser = (message) => async (dispatch) => {
    dispatch({
        type: USER_LOGOUT,
    });
    dispatch(showInfoNotification(message));
};
