import React from 'react';
import { useSelector } from 'react-redux';
import '../assets/css/Notifications.css';
import InfoNotification from './InfoNotification';
import ErrorNotification from './ErrorNotification';
import { getNotifications } from '../reducers';

function Notifications() {
    const notifications = useSelector(getNotifications);
    return (
        <div className="notifications">
            {notifications.length > 0 && notifications.map((notification) => {
                let result = null;
                if (notification.type === 'info') {
                    result = <InfoNotification key={notification.id} notification={notification} />;
                } else if (notification.type === 'error') {
                    result = <ErrorNotification key={notification.id} notification={notification} />;
                }
                return result;
            })}
        </div>
    );
}

export default Notifications;
