import traverse from 'traverse';
import { TYPE_MENU } from './Constants';

export const hasSubMenu = (node) => node.children && node.children.find((child) => child.type === TYPE_MENU) != null;

function hasChildren(obj) {
    return (Array.isArray(obj)) || (obj.children && obj.children.length > 0);
}

export const mapParentChildPairs = (f, node) => {
    if (hasChildren(node)) {
        if (Array.isArray(node)) {
            node.forEach((child) => {
                mapParentChildPairs(f, child);
            });
        } else if (node.children) {
            node.children.forEach((child) => {
                f(node, child);
                mapParentChildPairs(f, child);
            });
        }
    }
};

export const toggleParents = (node) => {
    if (node && node.parent) {
        // eslint-disable-next-line no-param-reassign
        node.parent.toggled = true;
        toggleParents(node.parent);
    }
};

export const findFirstNode = (tree, id) => {
    const nodes = traverse(tree).reduce((acc, node) => {
        if (node.id === id) acc.push(node);
        return acc;
    }, []);
    if (nodes.length > 0) {
        return nodes[0];
    }
    return null;
};

function isObject(node) {
    return typeof (node) == 'object';
}

export const toggleAll = (nodes) => {
    traverse(nodes)
        .forEach(
            (node) => {
                if (isObject(node) && node.type === TYPE_MENU) {
                    // eslint-disable-next-line no-param-reassign
                    node.toggled = true;
                }
            },
        );
};

export const initIds = (nodes) => {
    let id = 0;
    traverse(nodes)
        .forEach(
            (node) => {
                if (isObject(node)) {
                    // eslint-disable-next-line no-param-reassign
                    node.id = id.toString();
                    id++;
                }
            },
        );
    // eslint-disable-next-line no-param-reassign
    nodes.sequenceId = id;
};

export const removeNode = (node) => {
    // eslint-disable-next-line no-param-reassign
    node.parent.children = node.parent.children.filter((value) => value.id !== node.id);
};

export const nodesToString = (bookmarks) => JSON.stringify({ bookmarks }, replacer, 2);

function replacer(key, value) {
    // Filtering out parent & id
    if (key === 'parent' || key === 'id' || key === 'toggled' || key === 'active' || key === 'sequenceId' || key === 'edit') {
        return undefined;
    }
    return value;
}
