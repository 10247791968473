import uuid from 'uuid';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const showInfoNotification = (text) => showNotification(text, 'info');
export const showErrorNotification = (text) => showNotification(text, 'error');
export const showNotification = (text, type) => {
    const id = uuid.v4();
    return {
        type: SHOW_NOTIFICATION,
        payload: {
            id,
            text,
            type,
        },
    };
};

export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const hideNotification = (id) => ({
    type: HIDE_NOTIFICATION,
    payload: id,
});
