import React from 'react';
import PropTypes from 'prop-types';
import { TYPE_HTML, TYPE_LINK, TYPE_MENU } from '../utils/Constants';
import LinkItem from './LinkItem';
import MenuItem from './MenuItem';
import HtmlItem from './HtmlItem';

Item.propTypes = {
    // eslint-disable-next-line  react/forbid-prop-types
    item: PropTypes.object.isRequired,
};

function Item(props) {
    const { item } = props;
    switch (item.type) {
        case TYPE_LINK:
            return (<LinkItem item={item} />);
        case TYPE_MENU:
            return (<MenuItem item={item} />);
        case TYPE_HTML:
            return (<HtmlItem item={item} />);
        default:
            return (<></>);
    }
}

export default Item;
