import { useDispatch, useStore } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { findFirstNode, toggleParents } from '../utils/TreeUtil';
import { setTreeSelection } from '../actions/treeSelection';
import Menu from './Menu';

SelectedMenu.propTypes = {
    menuId: PropTypes.string.isRequired,
};

function SelectedMenu(props) {
    const { menuId } = props;
    const dispatch = useDispatch();
    const store = useStore();
    const { bookmarks } = store.getState();
    let initSelection = findFirstNode(bookmarks.menu, menuId);
    if (initSelection == null) {
        [initSelection] = bookmarks.menu;
    }
    toggleParents(initSelection);
    dispatch(setTreeSelection(initSelection));
    return (
        <Menu />
    );
}

export default SelectedMenu;
