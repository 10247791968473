import {
    USER_LOGIN_FAILURE,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
} from '../actions/authentication';

const userCredentials = (state = null, action = undefined) => {
    switch (action.type) {
        case USER_LOGIN_SUCCESS:
            return action.payload;
        case USER_LOGIN_FAILURE:
            return null;
        case USER_LOGOUT:
            return null;
        default:
            return state;
    }
};

export default userCredentials;
