import React from 'react';
import { useSelector, useStore } from 'react-redux';
import '../assets/css/Root.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Menu from './Menu';
import Content from './Content';
import Home from './Home';
import { loadBookmarks } from '../actions/bookmarks';
import { loadVariables } from '../actions/variables';
import SelectedMenu from './SelectedMenu';
import { getVariables, getBookmarks, getUserCredentials } from '../reducers';
import BookmarksManager from './BookmarksManager';
import Notifications from './Notifications';

function Root() {
    const store = useStore();
    const bookmarks = useSelector(getBookmarks);
    const variables = useSelector(getVariables);
    useSelector(getUserCredentials);

    if (!variables.OUTDOOR_TEMPERATURE) {
        store.dispatch(loadVariables());
        return (<Notifications />);
    }
    if (!bookmarks.menu) {
        store.dispatch(loadBookmarks());
        return (<Notifications />);
    }
    return (
        <Router>
            <Notifications />
            <div className="Root">
                <div className="MenuPanel">
                    <Switch>
                        <Route path="/menu/:id" render={({ match }) => <SelectedMenu menuId={match.params.id} />} />
                        <Route component={Menu} />
                    </Switch>
                </div>
                <div className="ContentPanel">
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/menu/:id" render={({ match }) => <Content menuId={match.params.id} />} />
                        <Route path="/bookmarks" component={BookmarksManager} />
                    </Switch>
                </div>
            </div>
        </Router>
    );
}

export default Root;
