import React from 'react';
import PropTypes from 'prop-types';
import '../assets/css/HtmlItem.css';

HtmlItem.propTypes = {
    // eslint-disable-next-line  react/forbid-prop-types
    item: PropTypes.object.isRequired,
};

function HtmlItem(props) {
    const { item } = props;
    return (
        // eslint-disable-next-line react/no-danger
        <div className="HtmlItem" dangerouslySetInnerHTML={{ __html: item.html }} />
    );
}

export default HtmlItem;
