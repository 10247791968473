import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../assets/css/LinkItem.css';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { getUserCredentials } from '../reducers';
import EditControl from './EditControl';
import { removeNode } from '../utils/TreeUtil';
import { setBookmarks } from '../actions/bookmarks';

LinkItem.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    item: PropTypes.object.isRequired,
};

function LinkItem(props) {
    const { item } = props;
    const user = useSelector(getUserCredentials);
    const store = useStore();
    const dispatch = useDispatch();
    const { bookmarks } = store.getState();
    const [node] = useState(item);
    const [name, setName] = useState(item.name);
    const [url, setUrl] = useState(item.url);
    const [edit, setEdit] = useState(typeof item.edit == 'undefined' ? false : item.edit);

    const onEdit = () => {
        setEdit(true);
    };
    const onRemove = () => {
        removeNode(node);
        dispatch(setBookmarks({ ...bookmarks }));
    };
    const onSubmitChanges = () => {
        setEdit(false);
        node.name = name;
        node.url = url;
        dispatch(setBookmarks({ ...bookmarks }));
    };

    return (
        <div>
            {edit
                ? (
                    <>
                        <input type="text" size="40" value={name} onChange={(event) => setName(event.target.value)} />
                        <input type="text" size="80" value={url} onChange={(event) => setUrl(event.target.value)} />
                    </>
                )
                : (
                    <div className="LinkItemText">
                        {item.name} - <a href={item.url}>{item.url}</a>
                    </div>
                )}
            {user && (
                <div className="LinkItemEditControl">
                    <EditControl initEdit={edit} item={item} onEditCallback={onEdit} onSubmitChangesCallback={onSubmitChanges} onRemoveCallback={onRemove} />
                </div>
            )}
        </div>
    );
}

export default LinkItem;
