import { createLogger } from '../utils/LoggerFactory';
import { withBackendService, toServiceUrl } from '../utils';

const logger = createLogger('Variables');

export const SET_VARIABLES = 'SET_VARIABLES';
export const setVariables = (variables) => ({
    type: SET_VARIABLES,
    payload: variables,
});

export const CLEAR_VARIABLES = 'CLEAR_VARIABLES';
export const clearVariables = () => ({
    type: CLEAR_VARIABLES,
});

export const LOAD_VARIABLES = 'LOAD_VARIABLES';
export const loadVariables = () => (dispatch) => {
    const blankVariables = {};
    blankVariables.OUTDOOR_TEMPERATURE = '-';
    blankVariables.POOL_TEMPERATURE = '-';
    if (withBackendService()) {
        const url = toServiceUrl('/variables');
        logger.debug(`Loading variables from ${url}`);
        fetch(url)
            .then((r) => r.json())
            .then((json) => processJson(dispatch, json))
            .catch(
                (err) => {
                    dispatch({
                        type: SET_VARIABLES,
                        payload: blankVariables,
                    });
                    logger.error(err.toString());
                });
    } else {
        dispatch({
            type: SET_VARIABLES,
            payload: blankVariables,
        });
    }
};

const processJson = (dispatch, json) => {
    const { state, payload } = json;
    logger.debug(state);
    if (state === 'OK') {
        const variables = payload;
        logger.debug(variables);
        dispatch({
            type: SET_VARIABLES,
            payload: variables,
        });
    }
};
