import { combineReducers } from 'redux';
import treeSelection from './treeSelection';
import bookmarks from './bookmarks';
import variables from './variables';
import notifications from './notifications';
import userCredentials from './authentication';

export default combineReducers({
    treeSelection,
    bookmarks,
    variables,
    notifications,
    userCredentials,
});

export const getNotifications = (state) => state.notifications;
export const getTreeSelection = (state) => state.treeSelection;
export const getBookmarks = (state) => state.bookmarks;
export const getVariables = (state) => state.variables;
export const getUserCredentials = (state) => state.userCredentials;
