import React, { useState } from 'react';
import { decorators, Treebeard } from 'react-treebeard';
import { useDispatch, useStore } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import TreeNodeDecorator from './TreeNodeDecorator';
import customTreeStyle from '../assets/themes/custom';
import { hasSubMenu } from '../utils/TreeUtil';
import { setTreeSelection } from '../actions/treeSelection';
import { loginSuccess, logoutUser } from '../actions/authentication';
import { withBackendService } from '../utils';
import homeIcon from '../assets/images/home-orange.png';

const Menu = () => {
    const store = useStore();
    const { bookmarks, treeSelection, userCredentials } = store.getState();
    const dispatch = useDispatch();
    const [data, setData] = useState(bookmarks.menu);
    const [redirect, setRedirect] = useState(false);
    const renderRedirect = () => {
        if (redirect) {
            return <Redirect to={`/menu/${treeSelection.id}`} />;
        }
        return null;
    };

    const onToggle = (node, toggled) => {
        const wasActive = node === treeSelection && treeSelection.active;
        if (hasSubMenu(node) && wasActive) {
            // eslint-disable-next-line no-param-reassign
            node.toggled = toggled;
        }
        setRedirect(true);
        dispatch(setTreeSelection(node));
        setData(Object.assign([], data));
    };
    const loginOnClick = (event) => {
        event.preventDefault();
        dispatch(loginSuccess('user'));
    };
    const logoutOnClick = (event) => {
        dispatch(logoutUser('The user was logged out.'));
    };
    return (
        <>
            {renderRedirect()}
            <div className="text-center"><h3>Menu</h3></div>
            <div className="text-center"><Link to="/"><img src={homeIcon} alt="Home" width={35} height={35} /></Link></div>
            <Treebeard
                data={data}
                onToggle={onToggle}
                style={customTreeStyle}
                decorators={{ ...decorators, Header: TreeNodeDecorator }}
            />
            {withBackendService()
            && (
                <div className="flex-container-row text-center">
                    {userCredentials
                        ? (
                            <>
                                <div className="flex-grow">
                                    <Link to="/" onClick={logoutOnClick}>
                                        <div className="fa fa-user-times big-icon" />
                                    </Link>
                                </div>
                                <div className="flex-grow">
                                    <Link to="/bookmarks">
                                        <div className="fa fa-cogs big-icon" />
                                    </Link>
                                </div>
                            </>
                        ) : (
                            <div className="flex-grow">
                                <button type="button" className="link-button" onClick={loginOnClick}>
                                    <div className="fa fa-user big-icon" />
                                </button>
                            </div>
                        )}
                </div>
            )}
        </>
    );
};

export default Menu;
