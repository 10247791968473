import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../assets/css/MenuItem.css';
import { useDispatch, useSelector, useStore } from 'react-redux';
import EditControl from './EditControl';
import { getUserCredentials } from '../reducers';
import { removeNode } from '../utils/TreeUtil';
import { setBookmarks } from '../actions/bookmarks';

MenuItem.propTypes = {
    // eslint-disable-next-line  react/forbid-prop-types
    item: PropTypes.object.isRequired,
};

function MenuItem(props) {
    const { item } = props;
    const store = useStore();
    const dispatch = useDispatch();
    const { bookmarks } = store.getState();
    const [node] = useState(item);
    const [name, setName] = useState(item.name);
    const [edit, setEdit] = useState(typeof item.edit == 'undefined' ? false : item.edit);
    const user = useSelector(getUserCredentials);

    const onEdit = () => {
        setEdit(true);
    };
    const onRemove = () => {
        removeNode(node);
        dispatch(setBookmarks({ ...bookmarks }));
    };
    const onSubmitChanges = () => {
        setEdit(false);
        node.name = name;
        dispatch(setBookmarks({ ...bookmarks }));
    };

    return (
        <div className="MenuItem">
            <div className="fa fa-folder MenuItemIcon" />
            <div className="MenuItemText">
                {edit
                    ? (<input type="text" size="40" value={name} onChange={(event) => setName(event.target.value)} />)
                    : (
                        <Link to={`/menu/${node.id}`}>
                            {node.name}
                        </Link>
                    )}
            </div>
            {user && <EditControl initEdit={edit} item={item} onEditCallback={onEdit} onSubmitChangesCallback={onSubmitChanges} onRemoveCallback={onRemove} />}
        </div>
    );
}

export default MenuItem;
