import { REMOVE_TREE_SELECTION, SET_TREE_SELECTION } from '../actions/treeSelection';
import { nodesToString, toggleParents } from '../utils/TreeUtil';
import { createLogger } from '../utils/LoggerFactory';

const logger = createLogger('TreeSelection');

const treeSelection = (state = {}, action = undefined) => {
    switch (action.type) {
        case SET_TREE_SELECTION: {
            if (state) {
                // eslint-disable-next-line no-param-reassign
                state.active = false;
            }
            const node = action.payload;
            node.active = true;
            toggleParents(node);
            logger.debug(nodesToString(node));
            return node;
        }
        case REMOVE_TREE_SELECTION:
            return {};
        default:
            return state;
    }
};

export default treeSelection;
