import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import './assets/css/Root.css';
import Root from './components/Root';
import configureStore from './configureStore';

const store = configureStore();

function App() {
    return (
        <ReduxProvider store={store}>
            <Root />
        </ReduxProvider>
    );
}

export default App;
