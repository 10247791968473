import { createLogger } from '../utils/LoggerFactory';

let logger = null;

const loggingMiddleware = (store) => (next) => (action) => {
    if (!logger) {
        logger = createLogger('LoggingMiddleware');
    }
    try {
        const stringifiedAction = JSON.stringify(action);
        logger.debug(`Dispatching: ${stringifiedAction}`);
    } catch (ex) {
        logger.debug(`Dispatching type: ${action.type}`);
    }
    next(action);
};

export default loggingMiddleware;
