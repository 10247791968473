import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVariables, getBookmarks, getUserCredentials } from '../reducers';
import { TYPE_LINK } from '../utils/Constants';
import '../assets/css/Content.css';
import Item from './Item';
import { setBookmarks } from '../actions/bookmarks';
import { toServiceUrl } from '../utils';

function Home() {
    const bookmarks = useSelector(getBookmarks);
    const variables = useSelector(getVariables);
    const { left, right, bottom } = bookmarks.home;
    const dispatch = useDispatch();
    const user = useSelector(getUserCredentials);
    const whereUrl = toServiceUrl('/whereIsCar');
    const onAddLink = () => {
        bookmarks.sequenceId++;
        const newID = bookmarks.sequenceId.toString();
        right.children.push({
            name: '', type: 'link', url: 'http://', parent: right, id: newID, edit: true,
        });
        dispatch(setBookmarks({ ...bookmarks }));
    };

    return (
        <div className="content-padding">
            <div className="content-header">Bookmarks</div>
            <div className="flex-container-row max-width">
                <div className="flex-grow">{
                    left.children
                        .filter((child) => child.type === TYPE_LINK)
                        .map((child) => (<Item item={child} key={child.id} />))
                }
                </div>
                <div className="content-right flex-grow">
                    {right.children
                        .sort((n1, n2) => (n1.name === '' ? 1 : n1.name.localeCompare(n2.name)))
                        .map((child) => (<Item item={child} key={child.id} />))}
                    {user && (
                        <button type="button" className="link-button" onClick={onAddLink}>
                            <div className="fa fa-plus MenuItemIcon" />
                            <div className="fa fa-file-o MenuItemIcon" />
                        </button>
                    )}
                </div>
            </div>
            <div className="content-bottom flex-container-column max-width">
                {bottom.children.map((child) => (<Item item={child} key={child.id} />))}
                <div className="LinkItemText">Nehvizdy: {variables.OUTDOOR_TEMPERATURE}&#8451; Pool: {variables.POOL_TEMPERATURE}&#8451;
                    { variables.CAR_STATUS != null
                    && (
                        <>
                            &nbsp;Battery: {variables.CAR_STATUS.attributes.batteryLevel}%
                            &nbsp;Time: {new Date(variables.CAR_STATUS.serverTime).toLocaleString()}
                            <form action={whereUrl} method="GET" target="_top"><input id="token" name="token" size="1" /><button type="submit">&raquo;</button></form>
                        </>
                    ) }
                </div>
            </div>
        </div>
    );
}

export default Home;
