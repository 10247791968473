import { JL } from 'jsnlog';

export const defaultConsoleAppender = JL.createConsoleAppender('defaultConsoleAppender');
export const defaultAppenders = [
    defaultConsoleAppender,
];

export const createLogger = (name) => {
    if (typeof name !== 'string' || !name || name.trim().length === 0) {
        throw new Error('Provide descriptive logger name!');
    }
    return JL(name).setOptions({
        appenders: defaultAppenders,
        level: process.env.NODE_ENV === 'development' || process.env.REACT_APP_WITH_JS_TRACE_LOGGING
            ? JL.getTraceLevel()
            : JL.getWarnLevel(),
    });
};
