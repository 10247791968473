import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBookmarks, getUserCredentials } from '../reducers';
import '../assets/css/BookmarksSourceView.css';
import { nodesToString } from '../utils/TreeUtil';
import { showErrorNotification, showInfoNotification } from '../actions/notifications';
import { toServiceUrl } from '../utils';

BookmarksManager.propTypes = {};

function BookmarksManager() {
    const bookmarks = useSelector(getBookmarks);
    const user = useSelector(getUserCredentials);
    const [data, setData] = useState(nodesToString(bookmarks));
    const [token, setToken] = useState('');
    const dispatch = useDispatch();

    const saveOnClick = (event) => {
        const url = toServiceUrl('/upload');
        fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            },
            body: JSON.stringify({ payload: data, token }),
        })
            .then((r) => r.json())
            .then((json) => {
                const { state, msg } = json;
                if (state === 'OK') {
                    dispatch(showInfoNotification(msg));
                } else {
                    dispatch(showErrorNotification(msg));
                }
            })
            .catch(
                (err) => {
                    dispatch(showErrorNotification(err.toString()));
                });
    };
    const dataOnChange = (event) => {
        setData(event.target.value);
    };

    const saveTokenOnChange = (event) => {
        setToken(event.target.value);
    };

    return (
        <>
            {user && (
                <>
                    <div>
                        <div className="tokenText">Token</div>
                        <input value={token} onChange={saveTokenOnChange} size={50} />
                        <button type="button" className="link-button" onClick={saveOnClick}>
                            <div className="fa fa-save big-icon" />
                        </button>
                    </div>
                    <textarea className="bookmarksSourceViewTextArea" value={data} onChange={dataOnChange} />
                </>
            )}
        </>
    );
}

export default BookmarksManager;
