import { CLEAR_BOOKMARKS, LOAD_BOOKMARKS, SET_BOOKMARKS } from '../actions/bookmarks';

const bookmarks = (state = {}, action = undefined) => {
    switch (action.type) {
        case SET_BOOKMARKS:
            return action.payload;
        case CLEAR_BOOKMARKS:
            return {};
        case LOAD_BOOKMARKS:
            return state;
        default:
            return state;
    }
};

export default bookmarks;
