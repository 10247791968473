import { initIds, mapParentChildPairs, toggleAll } from '../utils/TreeUtil';
import { createLogger } from '../utils/LoggerFactory';
import { showErrorNotification } from './notifications';
import { withBackendService, toServiceUrl } from '../utils';

const logger = createLogger('Bookmarks');

export const SET_BOOKMARKS = 'SET_BOOKMARKS';
export const setBookmarks = (bookmarks) => ({
    type: SET_BOOKMARKS,
    payload: bookmarks,
});

export const CLEAR_BOOKMARKS = 'CLEAR_BOOKMARKS';
export const clearBookmarks = () => ({
    type: CLEAR_BOOKMARKS,
});

export const LOAD_BOOKMARKS = 'LOAD_BOOKMARKS';
export const loadBookmarks = () => (dispatch) => {
    if (withBackendService()) {
        const url = toServiceUrl('/bookmarks');
        logger.debug(`Loading bookmarks from ${url}`);
        fetch(url)
            .then((r) => r.json())
            .then((json) => processJson(dispatch, json))
            .catch(
                (err) => {
                    dispatch(showErrorNotification(err.toString()));
                    dispatch(loadDefaultBookmarks());
                    logger.error(err.toString());
                });
    } else {
        dispatch(loadDefaultBookmarks());
    }
};

export const loadDefaultBookmarks = () => (dispatch) => {
    logger.info(`Loading default bookmarks from ${process.env.PUBLIC_URL}/bookmarks.json...`);
    fetch(`${process.env.PUBLIC_URL}/bookmarks.json`)
        .then((r) => r.json())
        .then((json) => processJson(dispatch, json))
        .catch(
            (err) => {
                dispatch(showErrorNotification(err.toString()));
                logger.error(err.toString());
            });
};

const processJson = (dispatch, json) => {
    const { state, msg, payload } = json;
    if (state === 'OK') {
        const { bookmarks } = payload;
        normalizeBookmarks(bookmarks);
        // console.log('Loaded data...');
        // console.log(bookmarks);
        dispatch({
            type: SET_BOOKMARKS,
            payload: bookmarks,
        });
    } else {
        dispatch(showErrorNotification(msg));
    }
};

const normalizeBookmarks = (bookmarks) => {
    initIds(bookmarks);
    toggleAll(bookmarks);
    mapParentChildPairs((parent, child) => {
        // eslint-disable-next-line no-param-reassign
        child.parent = parent;
    }, bookmarks.menu);
    mapParentChildPairs((parent, child) => {
        // eslint-disable-next-line no-param-reassign
        child.parent = parent;
    }, bookmarks.home.right);
    mapParentChildPairs((parent, child) => {
        // eslint-disable-next-line no-param-reassign
        child.parent = parent;
    }, bookmarks.home.bottom);
};
