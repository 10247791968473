import { CLEAR_VARIABLES, LOAD_VARIABLES, SET_VARIABLES } from '../actions/variables';

const variables = (state = {}, action = undefined) => {
    switch (action.type) {
        case SET_VARIABLES:
            return action.payload;
        case CLEAR_VARIABLES:
            return {};
        case LOAD_VARIABLES:
            return state;
        default:
            return state;
    }
};

export default variables;
