import React from 'react';
import PropTypes from 'prop-types';
import { hasSubMenu } from '../utils/TreeUtil';
import { Div } from './common';

// Example: Customising The TreeNodeDecorator Decorator To Include Icons
const TreeNodeDecorator = ({ style, node }) => {
    const visible = node.type === 'menu';
    // const iconType = hasSubMenu(node) ? 'folder' : 'file-text';
    const iconType = hasSubMenu(node) ? 'folder' : '';
    const iconClass = `fa fa-${iconType}`;
    const iconStyle = { marginRight: '5px' };

    if (!visible) {
        return null;
    }
    return (
        <Div style={style.base}>
            <Div style={style.title}>
                <i className={iconClass} style={iconStyle} />
                {node.name}
            </Div>
        </Div>
    );
};

TreeNodeDecorator.propTypes = {
    // eslint-disable-next-line  react/forbid-prop-types
    node: PropTypes.object.isRequired,
    // eslint-disable-next-line  react/forbid-prop-types
    style: PropTypes.object.isRequired,
};

export default TreeNodeDecorator;
