import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../assets/css/MenuItem.css';

EditControl.propTypes = {
    // eslint-disable-next-line  react/forbid-prop-types
    // item: PropTypes.object.isRequired,
    initEdit: PropTypes.bool,
    onSubmitChangesCallback: PropTypes.func.isRequired,
    onEditCallback: PropTypes.func.isRequired,
    onRemoveCallback: PropTypes.func.isRequired,
};

EditControl.defaultProps = {
    initEdit: false,
};

function EditControl(props) {
    const { initEdit } = props;
    const { onEditCallback, onRemoveCallback, onSubmitChangesCallback } = props;
    const [edit, setEdit] = useState(initEdit);

    const onEdit = (event) => {
        event.preventDefault();
        setEdit(true);
        onEditCallback();
    };
    const onRemove = (event) => {
        event.preventDefault();
        onRemoveCallback();
    };
    const onSubmitChanges = (event) => {
        event.preventDefault();
        setEdit(false);
        onSubmitChangesCallback();
    };

    return edit ? (
        <button type="button" className="link-button" onClick={onSubmitChanges}>
            <div className="fa fa-check MenuItemIcon" />
        </button>
    ) : (
        <>
            <button type="button" className="link-button" onClick={onEdit}>
                <div className="fa fa-edit MenuItemIcon" />
            </button>
            <button type="button" className="link-button" onClick={onRemove}>
                <div className="fa fa-remove MenuItemIcon" />
            </button>
        </>
    );
}

export default EditControl;
